import React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';

import Facebook from '@mui/icons-material/Facebook';
import YouTube from '@mui/icons-material/YouTube';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';

import CopyrightAutopilot from "./CopyrightAutopilot";

const Footer = () => {
  return (
    <div className="site-footer bg-grey">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="footer-title">Halingo</div>
            <ul>
              <li>
                <Link to="/features">
                  <Trans>Features</Trans>
                </Link>
              </li>
              <li>
                <Link to="/prices">
                  <Trans>Prijzen</Trans>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <Trans>Contact</Trans>
                </Link>
              </li>
              <li>
                <Link to="/blogs">
                  <Trans>Blog</Trans>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="footer-title">Bedrijf</div>
            <ul>
              <li>
                <Link to="/gdpr">
                  <Trans>Algemene voorwaarden</Trans>
                </Link>
              </li>
              <li>
                <Link to="/toc">
                  <Trans>Privacy verklaring</Trans>
                </Link>
              </li>
              {/*<li><a href="#">Over ons</a></li>*/}
            </ul>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="footer-title">Support</div>
            <ul>
              <li>
                <a href="https://halingo.zendesk.com">Helpcenter</a>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="footer-title">Community</div>
            <ul>
              <li>
                <a href="https://www.facebook.com/halingo.be"><Facebook /> Facebook</a>
              </li>
              <li>
                <a href="https://www.instagram.com/halingo.be"><Instagram /> Instagram</a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCRFDEW8Ec9kYHKwtFyZ7mkg/featured?view_as=subscriber"><YouTube /> YouTube</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/halingo/"><LinkedIn /> LinkedIn</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row pt-5 mt-5 text-center">
          <CopyrightAutopilot />
        </div>
      </div>
    </div>
  );
};

export default Footer;

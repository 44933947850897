import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/Layout";
import FAQ from "../components/Prices/FAQ";
import Header from "../components/Prices/Header";
import Payment from "../components/Prices/Payment";
import Popular from "../components/Prices/Popular";
import Testimonial from "../components/Prices/Testimonial";

const PricesPage = ({ location }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Layout location={location}>
        <Header
          title={t("Prices_Header_Title")}
          description={t("Prices_Header_Descrip")}
        />
        <div id="prices-section">
          <Popular
            Prices_Popular_Title_A={t("Prices_Popular_Title_A")}
            Prices_Popular_Title_B={t("Prices_Popular_Title_B")}
            Prices_Popular_Title_C={t("Prices_Popular_Title_C")}
            Prices_Popular_keyA={t("Prices_Popular_keyA")}
            Prices_Popular_Features_A={t("Prices_Popular_Features_A")}
            Prices_Popular_Features_B={t("Prices_Popular_Features_B")}
            Prices_Popular_Month={t("Prices_Popular_Month")}
            Prices_Popular_btn={t("Prices_Popular_btn")}
            Prices_Popular_Description={t("Prices_Popular_Description")}
            Prices_Popular_Terms={t("Prices_Popular_Terms")}
            Prices_Popular_Link={t("Prices_Popular_Link")}
          />
        </div>
        <Testimonial
          Prices_Testimonial_title={t("Prices_Testimonial_title")}
          Prices_Testimonial_subtitle={t("Prices_Testimonial_subtitle")}
          Prices_Testimonial_testiTitle={t("Prices_Testimonial_testiTitle")}
          Prices_Testimonial_username={t("Prices_Testimonial_username")}
          Prices_Testimonial_comment={t("Prices_Testimonial_comment")}
          Prices_Testimonial_sbUser={t("Prices_Testimonial_sbUser")}
          Prices_Testimonial_comp={t("Prices_Testimonial_comp")}
          Prices_Testimonial_title_B={t("Prices_Testimonial_title_B")}
          Prices_Testimonial_subtitle_B={t("Prices_Testimonial_subtitle_B")}
          Prices_Testimonial_testiTitle_B={t("Prices_Testimonial_testiTitle_B")}
          Prices_Testimonial_username_B={t("Prices_Testimonial_username_B")}
          Prices_Testimonial_comment_B={t("Prices_Testimonial_comment_B")}
          Prices_Testimonial_sbUser_B={t("Prices_Testimonial_sbUser_B")}
          Prices_Testimonial_comp_B={t("Prices_Testimonial_comp_B")}
          Prices_Testimonial_title_C={t("Prices_Testimonial_title_C")}
          Prices_Testimonial_subtitle_C={t("Prices_Testimonial_subtitle_C")}
          Prices_Testimonial_testiTitle_C={t("Prices_Testimonial_testiTitle_C")}
          Prices_Testimonial_username_C={t("Prices_Testimonial_username_C")}
          Prices_Testimonial_comment_C={t("Prices_Testimonial_comment_C")}
          Prices_Testimonial_sbUser_C={t("Prices_Testimonial_sbUser_C")}
          Prices_Testimonial_comp_C={t("Prices_Testimonial_comp_C")}
        />
        <Payment
          Prices_payment_Title={t("Prices_payment_Title")}
          Prices_payment_Description={t("Prices_payment_Description")}
        />
        <FAQ
          Prices_FAQ_Title={t("Prices_FAQ_Title")}
          Prices_FAQ_Qs_A={t("Prices_FAQ_Qs_A")}
          Prices_FAQ_Qs_B={t("Prices_FAQ_Qs_B")}
          Prices_FAQ_Qs_C={t("Prices_FAQ_Qs_C")}
          Prices_FAQ_Qs_D={t("Prices_FAQ_Qs_D")}
          Prices_FAQ_Qs_E={t("Prices_FAQ_Qs_E")}
          Prices_FAQ_AN_A={t("Prices_FAQ_AN_A")}
          Prices_FAQ_AN_B={t("Prices_FAQ_AN_B")}
          Prices_FAQ_AN_C={t("Prices_FAQ_AN_C")}
          Prices_FAQ_AN_D={t("Prices_FAQ_AN_D")}
          Prices_FAQ_AN_E={t("Prices_FAQ_AN_E")}
          Prices_help_Center={t("Prices_help_Center")}
        />
      </Layout>
    </div>
  );
};

export default PricesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

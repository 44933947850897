import React from "react";
import * as styles from "./bloghead.module.css";


const BlogHead = () => {

  return (
    <div>
      <div className={styles.mainSection}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <h1>
              <span>Blog</span>
            </h1>
            <p> Hier vindt u artikels over logopedie </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogHead;


import React from "react";
import { graphql } from "gatsby";
import {  useTranslation } from "gatsby-plugin-react-i18next";

import Header from "../components/Home/Header";
import Behoud from "../components/Home/Behoud";
import MeerVrije from "../components/Home/MeerVrije";
import FastAdmin from "../components/Home/FastAdmin";
import Devices from "../components/Home/Devices";
import Halingo from "../components/Home/Halingo";
import Voor from "../components/Home/Voor";

import Layout from "../components/Layout";


const IndexPage = ({ location }) => {
  const { t } = useTranslation();


  return (
    <div>
      <Layout location={location}>
        <Header
          title={t("Home_Header_Title")}
          subtitle={t("Home_Header_SubTitle")}
          btnTitle={t("Home_Header_BtnTitle")}
        />
        <Behoud
          title={t("Home_Behoud_Title")}
          description={t("Home_Behoud_Description")}
        />
        <MeerVrije
          title={t("Home_MeerVrije_Title")}
          description={t("Home_MeerVrije_Description")}
          descriptionB={t("Home_MeerVrije_Description_B")}
        />
        <FastAdmin
          title={t("Home_FastAdmin_Title")}
          description={t("Home_FastAdmin_Description")}
        />
        <Devices title={t("Home_Devices_Title")} />
        <Halingo title={t("Home_Halingo_Title")} />
        <Voor title={t("Home_Voor_Title")} btnTitle={t("Home_Voor_BtnTitle")} />
      </Layout>
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

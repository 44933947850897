import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

const LanguageMenu = ({ sticky, sidebar,  pathname }) => {
  const { originalPath } = useI18next();

  return (
    <div>
      <Link
        to={originalPath}
        language="nl"
        className={`  ${sticky || sidebar ? ' text-black' : ' text-white '} ${
          pathname === originalPath ? 'onscrol-text' : ''
        } w-full px-1 menu-nav-link`}
        style={{
          cursor: 'pointer',
          textTransform: 'capitalize',
        }}
      >
        NL
      </Link>
      /
      <Link
        to={originalPath}
        language="fr"
        className={`  ${sticky || sidebar ? ' text-black' : ' text-white '} ${
          pathname === originalPath ? 'onscrol-text' : ''
        } w-full px-1 menu-nav-link`}
        style={{
          cursor: 'pointer',
          textTransform: 'capitalize',
        }}
      >
        FR
      </Link>
    </div>
  );
};

export default LanguageMenu;

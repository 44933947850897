import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className="privacy-wrapper">
      <div className="privacy-body">
        <div className="wrapper">
          <h2 className="poc-title">{t("privacy_para_1")}</h2>
          <div className="container-privacy">
            <p>
              {t("privacy_para_2")}
            </p>
            <p>
              {t("privacy_para_3")}
            </p>
            <p>
              {t("privacy_para_4")}
            </p>
            <p>
              {t("privacy_para_5")}
            </p>
            <p>
              {t("privacy_para_6")}
            </p>
            <p className="toc-article">
              {t("privacy_para_7")}
            </p>
            <p>
              {t("privacy_para_8")}
            </p>
            <p>
              {t("privacy_para_9")}
            </p>
            <p>{t("privacy_para_10")}</p>
            <ul>
              <li>{t("privacy_para_11")}</li>
              <li>{t("privacy_para_12")}</li>
            </ul>
            <p>{t("privacy_para_13")}</p>
            <ul>
              <li>{t("privacy_para_14")}</li>
              <li>{t("privacy_para_15")}</li>
              <li>{t("privacy_para_16")}</li>
              <li>{t("privacy_para_17")}</li>
              <li>{t("privacy_para_18")}</li>
              <li>{t("privacy_para_19")}</li>
              <li>{t("privacy_para_20")}</li>
              <li>{t("privacy_para_21")}</li>
              <li>{t("privacy_para_22")}</li>
              <li>{t("privacy_para_23")}</li>
              <li>{t("privacy_para_24")}</li>

            </ul>
            <p>{t("privacy_para_25")}</p>
            <ul>
              <li>{t("privacy_para_26")}</li>
              <li>{t("privacy_para_27")}</li>
            </ul>
            <p>{t("privacy_para_28")}</p>
            <ul>
              <li>{t("privacy_para_29")}</li>
              <li>{t("privacy_para_30")}</li>
              <li>{t("privacy_para_31")}</li>
              <li>{t("privacy_para_32")}</li>

            </ul>
            <p className="toc-article">{t("privacy_para_33")}</p>
            <p>
              {t("privacy_para_34")}
            </p>
            <ul>
              <li>{t("privacy_para_35")}</li>
              <li>{t("privacy_para_36")}</li>
              <li>{t("privacy_para_37")}</li>
              <li>{t("privacy_para_38")}</li>
              <li>{t("privacy_para_39")}</li>
              <li>{t("privacy_para_40")}</li>
              <li>{t("privacy_para_41")}</li>
              <li>{t("privacy_para_42")}</li>
              <li>{t("privacy_para_43")}</li>
              <li>{t("privacy_para_44")}</li>

            </ul>
            <p className="toc-article">
              {t("privacy_para_45")}
            </p>
            <p>
              {t("privacy_para_46")}
            </p>
            <p>
              {t("privacy_para_47")}
            </p>
            <p>
              {t("privacy_para_48")}
            </p>
            <p className="toc-article">{t("privacy_para_49")}</p>
            <p>
              {t("privacy_para_50")}
            </p>
            <p>
              {t("privacy_para_51")}
            </p>
            <p className="toc-article">{t("privacy_para_52")}</p>
            <p>
              {t("privacy_para_53")}
            </p>
            <ul>
              <li>{t("privacy_para_54")}</li>
              <li>{t("privacy_para_55")}</li>
              <li>{t("privacy_para_56")}</li>
              <li>{t("privacy_para_57")}</li>
              <li>{t("privacy_para_58")}</li>
              <li>{t("privacy_para_59")}</li>

            </ul>
            <p className="toc-article">
              {t("privacy_para_60")}
            </p>
            <p>
              {t("privacy_para_61")}
            </p>
            <p className="toc-article">{t("privacy_para_62")}</p>
            <p>
              {t("privacy_para_63")}
            </p>
            <p>
              {t("privacy_para_64")}
            </p>
            <p className="toc-article">{t("privacy_para_65")}</p>
            <p>
              {t("privacy_para_66")}
            </p>
            <p className="toc-article">{t("privacy_para_67")}</p>
            <p>
              {t("privacy_para_68")}
            </p>
            <p className="toc-article">{t("privacy_para_69")}</p>
            <p>
              {t("privacy_para_70")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

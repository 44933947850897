exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prices-tsx": () => import("./../../../src/pages/prices.tsx" /* webpackChunkName: "component---src-pages-prices-tsx" */),
  "component---src-pages-toc-tsx": () => import("./../../../src/pages/toc.tsx" /* webpackChunkName: "component---src-pages-toc-tsx" */),
  "component---src-templates-all-posts-js": () => import("./../../../src/templates/AllPosts.js" /* webpackChunkName: "component---src-templates-all-posts-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}


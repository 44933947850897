import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/Layout";
import Header from "../components/Features/Header";
import Features from "../components/Features/Features";
import Services from "../components/Features/Services";
import Insights from "../components/Features/Insights";
import Probeer from "../components/Features/Probeer";

const FeaturesPage = ({ location }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Layout location={location}>
        <Header
          title={t("Features_Header_Title")}
          description={t("Features_Header_Description")}
          btnTitle={t("Features_Header_BtnTitle")}
        />
        <div id="prices-section">
          <Features
            title={t("Features_FTR_Title")}
            description={t("Features_FTR_Description")}
            title_B={t("Features_FTR_Title_B")}
            description_B={t("Features_FTR_Description_B")}
            title_C={t("Features_FTR_Title_C")}
            description_C={t("Features_FTR_Description_C")}
            title_D={t("Features_FTR_Title_D")}
            description_D={t("Features_FTR_Description_D")}
          />
        </div>
        <Services
          Features_Services_icon_one={t("Features_Services_icon_one")}
          Features_Services_title_one={t("Features_Services_title_one")}
          Features_Services_description_one={t(
            "Features_Services_description_one"
          )}
          Features_Services_icon_two={t("Features_Services_icon_two")}
          Features_Services_title_two={t("Features_Services_title_two")}
          Features_Services_description_two={t(
            "Features_Services_description_two"
          )}
          Features_Services_icon_three={t("Features_Services_icon_three")}
          Features_Services_title_three={t("Features_Services_title_three")}
          Features_Services_description_three={t(
            "Features_Services_description_three"
          )}
          Features_Services_icon_four={t("Features_Services_icon_four")}
          Features_Services_title_four={t("Features_Services_title_four")}
          Features_Services_description_four={t(
            "Features_Services_description_four"
          )}
          Features_Services_icon_five={t("Features_Services_icon_five")}
          Features_Services_title_five={t("Features_Services_title_five")}
          Features_Services_description_five={t(
            "Features_Services_description_five"
          )}
          Features_Services_icon_six={t("Features_Services_icon_six")}
          Features_Services_title_six={t("Features_Services_title_six")}
          Features_Services_description_six={t(
            "Features_Services_description_six"
          )}
        />
        <Insights
          title={t("Features_Insights_Title_A")}
          description={t("Features_Insights_Description_A")}
          title_B={t("Features_Insights_Title_B")}
          description_B={t("Features_Insights_Description_B")}
        />
        <Probeer
          Features_Probeer_Title={t("Features_Probeer_Title")}
          Features_Probeer_Btn={t("Features_Probeer_Btn")}
        />
      </Layout>
    </div>
  );
};

export default FeaturesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

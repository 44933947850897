import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const FeatureItem = ({
  children,
  title,
  description,
}) => {
  return (
    <div
    className="col-6 col-md-4 solutions-container"
  >
    <div className="m-4">
    {children}
      <br />
      <strong>{title}</strong>
      <div className="solutions-container-text pt-3">
        {description}
      </div>
    </div>
  </div>


  )
}


export default FeatureItem;

import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Facebook from '@mui/icons-material/Facebook';
import YouTube from '@mui/icons-material/YouTube';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Layout from "../components/Layout";

const ContactPage = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout location={location}>
      <div className="site-wrap">
        <div
          className="site-blocks-cover-md contact-bg overlay"
        >
          <div className="container">
            <div
              className="row align-items-center align-items-md-center justify-content-md-center pb-5 pb-md-0"
            >
              <div className="col-md-5" style={{ zIndex: "1000" }}>
                <div className="row">
                  <div className="col-12 shadow contact-box mb-3 text-center d-flex flex-column align-items-center justify-content-center rounded">
                    <a
                      href="mailto:info@halingo.be?subject=Vraag via contact pagina"
                      style={{ fontSize: "1em", marginBottom: "10px" }}
                    >
                      {t("Contact_Address_Email")}
                    </a>
                    <a href="https://halingo.zendesk.com">
                      Helpcenter
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12 shadow contact-box text-center d-flex align-items-center justify-content-center rounded"
                    style={{ fontSize: "3em" }}
                  >
                    <a
                      href="https://www.facebook.com/halingo.be"
                      className="smoothscroll"
                    >
                      <Facebook fontSize="large"/>
                    </a>
                    <a
                      href="https://www.instagram.com/halingo.be"
                      className="smoothscroll"
                    >
                      <Instagram  fontSize="large"/>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCRFDEW8Ec9kYHKwtFyZ7mkg/featured?view_as=subscriber"
                      className="smoothscroll"
                    >
                      <YouTube fontSize="large"/>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/halingo/"
                      className="smoothscroll"
                    >
                      <LinkedIn fontSize="large"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

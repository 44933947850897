import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
const Toc = () => {
  const { t } = useTranslation();
  return (
    <div className="privacy-wrapper">
      <div className="privacy-body">
        <div className="wrapper">
          <h2 className="poc-title">{t("toc_title")}</h2>
          <div className="container-privacy">
            <span />
            <p className="toc-article">{t("toc_title_1")}</p>
            <p>{t("toc_title_2")}</p>
            <p>{t("toc_title_3")}</p>
            <ol>
              <li>
                <span>{t("toc_title_4")}</span>
              </li>
              <li>
                <span>{t("toc_title_5")}</span>
              </li>
            </ol>
            <p></p>
            <p className="toc-article">{t("toc_title_6")}</p>
            <ol>
              <li>
                <span>{t("toc_title_7")}</span>
              </li>
              <li>
                <span>{t("toc_title_8")}</span>
              </li>
              <li>
                <span>{t("toc_title_9")}</span>
              </li>
              <li>
                <span>{t("toc_title_10")}</span>
              </li>
              <li>
                <span>{t("toc_title_11")}</span>
              </li>
              <li>
                <span>{t("toc_title_12")}</span>
              </li>
              <li>
                <span>{t("toc_title_13")}</span>
              </li>
              <li>
                <span>{t("toc_title_14")}</span>
              </li>
              <li>
                <span>{t("toc_title_15")}</span>
              </li>
              <li>
                <span>{t("toc_title_16")}</span>
              </li>
              <li>
                <span>{t("toc_title_17")}</span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_18")}</p>
            <ol>
              <li>
                <span>{t("toc_title_19")}</span>
              </li>
              <li>
                <span>{t("toc_title_20")}</span>
              </li>
              <li>
                <span>{t("toc_title_21")}</span>
              </li>
              <li>
                <span>{t("toc_title_22")}</span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_23")}</p>
            <ol>
              <li>
                <span>{t("toc_title_24")}</span>
              </li>
              <li>
                <span>{t("toc_title_25")}</span>
              </li>
              <li>
                <span>{t("toc_title_26")}</span>
              </li>
              <li>
                <span>{t("toc_title_27")}</span>
              </li>
              <li>
                <span>{t("toc_title_28")}</span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_29")}</p>
            <ol>
              <li>
                <span>{t("toc_title_30")}</span>
              </li>
              <li>
                <span>{t("toc_title_31")}</span>
              </li>
              <li>
                <span>{t("toc_title_32")}</span>
              </li>
              <li>
                <span>{t("toc_title_33")}</span>
              </li>
              <li>
                <span>{t("toc_title_34")}</span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_35")}</p>
            <ol>
              <li>
                <span>{t("toc_title_36")}</span>
              </li>
              <li>
                <span>{t("toc_title_37")}</span>
              </li>
              <li>
                <span>{t("toc_title_38")}</span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_39")}</p>
            <ol>
              <li>
                <span>{t("toc_title_40")}</span>
              </li>
              <li>
                <span>
                  {t("toc_title_41")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_42")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_43")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_44")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_45")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_46")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_47")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_48")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_49")}
                  <a href="https://www.halingo.be/nl/gdpr.html">
                    {t("toc_title_50")}
                  </a>
                  .
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_51")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_52")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_53")}
                </span>
              </li>
              <li>
                <span>
                  D{t("toc_title_54")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_55")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_56")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_57")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_58")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_59")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_60")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_61")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_62")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_63")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_64")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_65")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_66")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_67")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_68")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_69")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_70")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_71")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_72")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_73")}
            </p>
            <ol>
              <li>
                <span>{t("toc_title_74")}</span>
              </li>
              <li>
                <span>
                  {t("toc_title_75")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_76")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_77")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_78")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_79")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_80")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_81")}
                </span>
              </li>
            </ol>
            <p className="toc-article">A{t("toc_title_82")}</p>
            <ol>
              <li>
                <span>
                  {t("toc_title_83")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_84")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_85")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_86")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_87")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_88")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_89")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_90")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_91")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_92")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_93")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_94")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_95")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_96")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_97")}
                </span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_98")}</p>
            <ol>
              <li>
                <span>
                  {t("toc_title_99")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_100")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_101")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_102")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_103")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_104")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_105")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_106")}
                </span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_107")}</p>
            <ol>
              <li>
                <span>
                  {t("toc_title_108")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_109")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_110")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_111")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_112")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_113")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_114")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_115")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_116")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_117")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_118")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_119")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_120")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_121")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_122")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_123")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_124")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_125")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_126")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_127")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_128")}
                </span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_129")}</p>
            <ol>
              <li>
                <span>
                  {t("toc_title_130")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_131")}
                </span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_132")}</p>
            <ol>
              <li>
                <span>
                  {t("toc_title_133")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_134")}
                </span>
              </li>
            </ol>
            <p className="toc-article">
              {t("toc_title_135")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_136")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_137")}
                </span>
              </li>
            </ol>
            <p className="toc-article">{t("toc_title_138")}</p>
            <p>
              {t("toc_title_139")}
            </p>
            <p className="toc-article">
              {t("toc_title_140")}
            </p>
            <ol>
              <li>
                <span>
                  {t("toc_title_141")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_142")}
                </span>
              </li>
              <li>
                <span>
                  {t("toc_title_143")}
                </span>
              </li>
            </ol>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toc;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

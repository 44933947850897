import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const Insights = ({ title, description, title_B, description_B }) => {
  return (
    <div>
      {" "}
      <div className="site-blocks-cover-md" id="verslag-bijhouden-section">
        <div className="container">
          <div
            className="row align-items-center justify-content-center"
          >
            <div className="col-md-6 px-3 py-5">
              <h2 style={{ textAlign: "center" }}>{title}</h2>
              <div
                className="mt-5"
                style={{ fontSize: "1.2em", textAlign: "center" }}
              >
                {description}
                <br />
              </div>
              <StaticImage
                src="../../images/nl/verslag_thumb.png"
                style={{ width: "350px" }}
                className="img-fluid mx-auto d-block mt-5"
                alt="Maak verslagen voor je patienten online"
              />
            </div>
            <div className="col-md-6 px-3 py-5">
              <h2 style={{ textAlign: "center" }}>{title_B}</h2>
              <div
                className="mt-5"
                style={{ fontSize: "1.2em", textAlign: "center" }}
              >
                {description_B}
              </div>
              <StaticImage
                src="../../images/nl/riziv_thumb.png"
                style={{ width: "350px" }}
                className="img-fluid mx-auto d-block mt-5"
                alt="Beheer RIZIV eenvoudig met Halingo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insights;

import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const Features = ({
  title,
  description,
  title_B,
  description_B,
  title_C,
  description_C,
  title_D,
  description_D,
}) => {
  return (
    <div>
      <div className="site-blocks" id="overview-section">
        <div className="container">
          <div
            className="row align-items-center"
          >
            <div className="col-12">
              <div className="row align-items-center py-5">
                <div className="col-lg-6 col-md-12">
                  <div className="text-halingo" style={{ fontSize: "1.8em" }}>
                    {title}
                  </div>
                  <div>{description}</div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <StaticImage
                    src="../../images/nl/agenda_thumb.png"
                    style={{ border: "1px solid #D3D3D3" }}
                    className="img-fluid"
                    alt="Beheer je logopedisten agenda online"
                  />
                </div>
              </div>
              <div className="row align-items-center py-5">
                <div className="col-lg-6 col-md-12">
                  <StaticImage
                    src="../../images/nl/patientendossier_thumb.png"
                    style={{ border: "1px solid #D3D3D3" }}
                    className="img-fluid"
                    alt="Beheer patienten dossier online voor logopedisten"
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="text-halingo" style={{ fontSize: "1.8em" }}>
                    {title_B}
                  </div>
                  <div>{description_B}</div>
                </div>
              </div>
              <div className="row align-items-center py-5">
                <div className="col-lg-6 col-md-12">
                  <div className="text-halingo" style={{ fontSize: "1.8em" }}>
                    {title_C}
                  </div>
                  <div>{description_C}</div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <StaticImage
                    src="../../images/nl/facturen_thumb.png"
                    style={{ border: "1px solid #D3D3D3" }}
                    className="img-fluid"
                    alt="Facturen voor logopedisten"
                  />
                </div>
              </div>
              <div className="row align-items-center py-5">
                <div className="col-lg-6 col-md-12">
                  <StaticImage
                    src="../../images/nl/riziv_thumb.png"
                    style={{ border: "1px solid #D3D3D3" }}
                    className="img-fluid"
                    alt="RIZIV integratie"
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="text-halingo" style={{ fontSize: "1.8em" }}>
                    {title_D}
                  </div>
                  <div>{description_D}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;

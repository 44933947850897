import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import slda from '../images/blank-card-cardboard.webp';

import * as styles from './posts.module.css';

import Blog from '../components/Blogs/Blog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Layout from '../components/Layout';

import BlogHead from '../components/Blogs/BlogHead/BlogHead';

const AllPosts = ({ pageContext, data }) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/blogs' : '/blogs/' + (currentPage - 1).toString();
  const nextPage = '/blogs/' + (currentPage + 1).toString();

  const posts = data.allMdx.edges;
  const getPageNumberPath = (currentIndex) => {
    if (currentIndex === 0) {
      return '/blogs';
    }

    return '/blogs/' + (currentIndex + 1);
  };
  return (
    <Layout location={'blogs'}>
      <BlogHead />
      <div className="container">
        <div className="row">
          {posts.map((post) => {
            return (
              <Blog
                key={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.slug
                    : ''
                }
                date={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.date
                    : ''
                }
                title={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.title
                    : ''
                }
                description={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.description
                    : ''
                }
                slug={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.slug
                    : ''
                }
                image={
                  post.node.frontmatter.og_image.publicURL !== undefined
                  ? post.node.frontmatter.og_image.publicURL
                  : slda
                }
              />
            );
          })}
        </div>

        <div className={styles.paginationWrapper}>
          <Link
            className={
              isFirst ? styles.disabledButton : styles.nextPageClassName
            }
            to={prevPage}
            rel="prev"
          >
            <span>
              <ChevronLeftIcon className={styles.nextPrev} />
            </span>
          </Link>
          {Array.from({ length: numPages }, (_, i) => {
            let pageNumberClassName = styles.pageNumber;
            if (currentPage === i + 1) {
              pageNumberClassName = styles.currentPageNumber;
            }

            return (
              <Link key={i + 1} to={getPageNumberPath(i)}>
                <span
                  className={
                    pageNumberClassName
                      ? pageNumberClassName
                      : styles.paginationNumber
                  }
                >
                  {i + 1}
                </span>
              </Link>
            );
          })}
          <Link
            className={
              isLast ? styles.disabledButton : styles.nextPageClassName
            }
            to={nextPage}
            rel="next"
          >
            <span>
              <ChevronRightIcon className={styles.nextPrev} />
            </span>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default AllPosts;

export const pageQuery = graphql`
  query AllPostsQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            date
            slug
            title
            tags
            description
            og_image {
              publicURL
            }
          }
        }
      }
    }
  }
`;

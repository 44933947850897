import React, { useState } from "react";
import inge from "../../images/logopedie-ten-bos.png";
import taalschat from "../../images/de-taalschat.png";
import groepspraktijk from "../../images/groepspraktijk-de-brug.png";
const Testimonial = ({
  Prices_Testimonial_title,
  Prices_Testimonial_subtitle,
  Prices_Testimonial_testiTitle,
  Prices_Testimonial_username,
  Prices_Testimonial_comment,
  Prices_Testimonial_sbUser,
  Prices_Testimonial_comp,
  Prices_Testimonial_title_B,
  Prices_Testimonial_subtitle_B,
  Prices_Testimonial_testiTitle_B,
  Prices_Testimonial_username_B,
  Prices_Testimonial_comment_B,
  Prices_Testimonial_sbUser_B,
  Prices_Testimonial_comp_B,
  Prices_Testimonial_title_C,
  Prices_Testimonial_subtitle_C,
  Prices_Testimonial_testiTitle_C,
  Prices_Testimonial_username_C,
  Prices_Testimonial_comment_C,
  Prices_Testimonial_sbUser_C,
  Prices_Testimonial_comp_C,
}) => {
  const [activeSlide, setActiveSlide] = useState(1);

  const sliderhandler = (id) => {
    setActiveSlide(id);
  };

  const data = [
    {
      id: 1,
      icon: inge,
      title: Prices_Testimonial_title,
      subTitle: Prices_Testimonial_subtitle,
      testiTitle: Prices_Testimonial_testiTitle,
      userName: Prices_Testimonial_username,
      comment: Prices_Testimonial_comment,
      sbUser: Prices_Testimonial_sbUser,
      comp: Prices_Testimonial_comp,
    },
    {
      id: 2,
      icon: taalschat,
      title: Prices_Testimonial_title_B,
      subTitle: Prices_Testimonial_subtitle_B,
      testiTitle: Prices_Testimonial_testiTitle_B,
      userName: Prices_Testimonial_username_B,
      comment: Prices_Testimonial_comment_B,
      sbUser: Prices_Testimonial_sbUser_B,
      comp: Prices_Testimonial_comp_B,
    },
    {
      id: 3,
      icon: groepspraktijk,
      title: Prices_Testimonial_title_C,
      subTitle: Prices_Testimonial_subtitle_C,
      testiTitle: Prices_Testimonial_testiTitle_C,
      userName: Prices_Testimonial_username_C,
      comment: Prices_Testimonial_comment_C,
      sbUser: Prices_Testimonial_sbUser_C,
      comp: Prices_Testimonial_comp_C,
    },
  ];

  return (
    <div>
      <div
        className="site-blocks-cover-md bg-halingo-light"
        id="comment-section"
      >
        <div className="container">
          <div
            className="row align-items-center"
          >
            <div className="col-md-4 shadow px-4 pt-3 pb-5 mt-3 recomm-container">
              {data.map((item, index) => {
                return (
                  <div
                    className="row mb-4"
                    key={index}
                    onClick={() => sliderhandler(item.id)}
                  >
                    <div className="  col-sm-4">
                      <img
                        src={item.icon}
                        alt=""
                        className={
                          activeSlide === item.id
                            ? `active-slider-image`
                            : `slider-image`
                        }
                      />
                    </div>
                    <div className="col-sm-8 mt-2 pt-1 ml-3">
                      <h5
                        className={
                          activeSlide === item.id
                            ? `active-slider-title`
                            : `slider-title`
                        }
                      >
                        {item.title}
                      </h5>
                      <h6 className="slider-subtitle">{item.subTitle}</h6>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-md-7 offset-md-1 mt-5 mt-md-0">
              {data.map((item, index) => {
                return (
                  <div key={index}>
                    {activeSlide === item.id ? (
                      <>
                        <h2 className="text-halingo mb-4 text-center">
                          {item.testiTitle}
                        </h2>
                        <span className="userName-slider">{item.userName}</span>
                        <div className="d-flex justify-content-center">
                          <div
                            className="d-flex mt-2"
                            style={{ fontSize: "1.2em" }}
                          >
                            <span
                              className="icon-star text-halingo "
                              aria-hidden="true"
                              style={{ display: "block", margin: "0px 12px" }}
                            ></span>
                            <span
                              className="icon-star text-halingo"
                              aria-hidden="true"
                              style={{ display: "block", margin: "0px 12px" }}
                            ></span>
                            <span
                              className="icon-star text-halingo"
                              aria-hidden="true"
                              style={{ display: "block", margin: "0px 12px" }}
                            ></span>
                            <span
                              className="icon-star text-halingo"
                              aria-hidden="true"
                              style={{ display: "block", margin: "0px 12px" }}
                            ></span>
                            <span
                              className="icon-star text-halingo"
                              aria-hidden="true"
                              style={{ display: "block", margin: "0px 12px" }}
                            ></span>
                          </div>
                        </div>

                        <div className="comment-info-container">
                          <span className="comment">{item.comment}</span>
                          <span className="sbUser">{item.sbUser}</span>
                          <span className="comp">{item.comp}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const Payment = ({ Prices_payment_Title, Prices_payment_Description }) => {
  return (
    <div>
      <div className="site-blocks-cover-md" id="payment-section">
        <div className="container">
          <div
            className="row align-items-center"
          >
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="text-halingo" style={{ textAlign: "center", marginTop: "32px" }}>
                  {Prices_payment_Title}
                </h2>
                <div className="mt-3 text-center" style={{ fontSize: "1.2em" }}>
                  {Prices_payment_Description}
                </div>
              </div>
              <div className="col-md-4 mt-5 text-center">
                <StaticImage
                  src="../../images/visa-logo.webp"
                  height={100}
                  alt="Logo voor visa"
                  layout="fixed"
                />
              </div>
              <div className="col-md-4 mt-5 text-center">
                <StaticImage
                  src="../../images/mastercard-logo.png"
                  height={100}
                  alt="Logo voor Mastercard"
                />
              </div>
              <div className="col-md-4 mt-5 text-center">
                <StaticImage
                  src="../../images/bancontact-logo.svg"
                  height={100}
                  alt="Logo voor bancontact"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;

import React from 'react';
import * as styles from './posts.module.css';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout';

const SinglePost = ({ data }) => {
  return (
    <Layout location={'blogs'}>
      <div className={styles.imgWrap}>
        <div className={styles.imageMask}>
          <img
            src={
              data.mdx.frontmatter.og_image !== undefined &&
              data.mdx.frontmatter.og_image !== null &&
              data.mdx.frontmatter.og_image.publicURL !== null &&
              data.mdx.frontmatter.og_image.publicURL !== undefined
                ? data.mdx.frontmatter.og_image.publicURL
                : ''
            }
            alt={data.mdx.frontmatter?.title}
            className={styles.headerImage}
          />
          <div  className={styles.singleblogTitleContainer}>
            <h1 className={styles.singleblogTitle}>
              {data.mdx.frontmatter?.title}
            </h1>
          </div>
        </div>
      </div>
      <div className=" container" style={{ paddingTop: '400px' }}>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </div>
    </Layout>
  );
};

export default SinglePost;

export const pageQuery = graphql`
  query SinglePostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        date
        description
        slug
        tags
        title
        og_image {
          publicURL
        }
      }
      body
    }
  }
`;

import React from "react";

const Header = ({ title, subtitle, btnTitle }) => {
  return (
    <div>
      <div
        className="site-blocks-cover header-home overlay"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12" style={{ zIndex: '1000' }}>
              <div className="row mb-4">
                <div className="col-md-8">
                  <h1 className="text-halingo">{title}</h1>
                  <p className="mb-5" style={{ fontSize: "2em" }}>
                    {subtitle}
                  </p>
                  <div>
                    <a
                      href="https://app.halingo.be/register"
                      className="btn btn-outline-halingo py-3 px-5 d-block d-sm-inline-block"
                      id="probeer_gratis"
                    >
                      {btnTitle}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "-5em" }}>
            <div className="col-2 offset-5" style={{ textAlign: "center" }}>
              <a href="#overview-section" className="smoothscroll">
                <span
                  className="icon-arrow-circle-down text-halingo"
                  aria-hidden="true"
                  style={{ fontSize: "4em" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import { StaticImage } from "gatsby-plugin-image"


const Halingo = ({title}) => {
  return (
    <div>
      {" "}
      <div
        className="site-blocks-cover"
        id="collaboration-section"
        style={{ height: "calc(100vh)" }}
      >
        <div className="container align-items-center">
          <div className="row">
            <div className="col-12 d-flex align-items-center">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 mt-5 mb-5">
                    <h2 style={{ textAlign: "center" }}>
                      {title}
                    </h2>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div
                    className="col-md-4 d-flex align-items-center justify-content-center p-2"
                  >
                    <div>
                      <a
                        href="https://www.riziv.fgov.be/nl/Paginas/default.aspx"
                      >
                        <StaticImage
                          src="../../images/riziv-logo.webp"
                          style={{ width: "200px" }}
                          className="img-fluid rounded mx-auto d-block"
                          alt="Riziv Halingo"
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-center justify-content-center p-2"
                    style={{ marginTop: 64 }}
                  >
                    <div>
                      <a href="https://sendgrid.com">
                        <StaticImage
                          src="../../images/sendgrid.png"
                          style={{ width: "200px" }}
                          className="img-fluid rounded mx-auto d-block"
                          alt="Sendgrid Halingo"
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-center justify-content-center p-2"
                    style={{ marginTop: 64 }}
                  >
                    <div>
                      <a href="https://stripe.com/en-be">
                        <StaticImage
                          src="../../images/stripe.png"
                          style={{ width: "200px" }}
                          className="img-fluid rounded mx-auto d-block"
                          alt="stripe Halingo logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Halingo;

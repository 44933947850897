// extracted by mini-css-extract-plugin
export var wrapper = "posts-module--wrapper---jfLW";
export var slider_main = "posts-module--slider_main--n6N4E";
export var rightBox = "posts-module--rightBox--OPbbt";
export var paginationWrapper = "posts-module--paginationWrapper--bnXAa";
export var currentPageNumber = "posts-module--currentPageNumber--PQJHP";
export var paginationNumber = "posts-module--paginationNumber--2eXR1";
export var nextPageClassName = "posts-module--nextPageClassName--OVeoL";
export var nextPrev = "posts-module--nextPrev--7BSn3";
export var disabledButton = "posts-module--disabledButton--m5vkA";
export var imgWrap = "posts-module--imgWrap--fLTTH";
export var imageMask = "posts-module--imageMask--0+-Mk";
export var headerImage = "posts-module--headerImage--BFQA1";
export var singleblogTitleContainer = "posts-module--singleblogTitleContainer--iayHn";
export var singleblogTitle = "posts-module--singleblogTitle--iQhVG";
import React from 'react';
import TodayIcon from '@mui/icons-material/Today';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FeatureItem from './FeatureItem';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SecurityIcon from '@mui/icons-material/Security';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const Services = ({
  Features_Services_title_one,
  Features_Services_description_one,
  Features_Services_title_two,
  Features_Services_description_two,
  Features_Services_title_three,
  Features_Services_description_three,
  Features_Services_title_four,
  Features_Services_description_four,
  Features_Services_title_five,
  Features_Services_description_five,
  Features_Services_title_six,
  Features_Services_description_six,
}) => {
  return (
    <div>
      <div
        className="site-blocks-cover-md halingo-bg mb-sm-5"
        id="infinite-solutions-section"
      >
        <div className="container">
          <div
            className="row align-items-center"
          >
            <div
              className="row justify-content-around"
              style={{ minHeight: '50%' }}
            >
              <FeatureItem
                title={Features_Services_title_one}
                description={Features_Services_description_one}
              >
                <TodayIcon sx={{ fontSize: 50 }} />
              </FeatureItem>
              <FeatureItem
                title={Features_Services_title_two}
                description={Features_Services_description_two}
              >
                <CreditCardIcon sx={{ fontSize: 50 }} />
              </FeatureItem>

              <FeatureItem
                title={Features_Services_title_three}
                description={Features_Services_description_three}
              >
                <LocalOfferIcon sx={{ fontSize: 50 }} />
              </FeatureItem>
              <FeatureItem
                title={Features_Services_title_four}
                description={Features_Services_description_four}
              >
                <CloudUploadIcon sx={{ fontSize: 50 }} />
              </FeatureItem>
              <FeatureItem
                title={Features_Services_title_five}
                description={Features_Services_description_five}
              >
                <SecurityIcon sx={{ fontSize: 50 }} />
              </FeatureItem>
              <FeatureItem
                title={Features_Services_title_six}
                description={Features_Services_description_six}
              >
                <QuestionAnswerIcon sx={{ fontSize: 50 }} />
              </FeatureItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

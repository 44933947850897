import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const Devices = ({ title })  => {
  return (
    <div>
      <div className="site-blocks-cover halingo-bg" id="devices-section">
        <div className="container">
          <div
            className="row align-items-center"
          >
            <div className="col-12">
              <h2 style={{ textAlign: "center" }} className="mb-5">
                {title}
              </h2>
              <StaticImage
                src="../../images/devices.png"
                className="rounded mx-auto d-block img-fluid"
                alt={title}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Devices;

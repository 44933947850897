import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const FastAdmin = ({ title, description }) => {
  return (
    <div>
      <div className="site-blocks-cover" id="fastadmin-section">
        <div className="container">
          <div
            className="row align-items-center"
          >
            <div className="col-lg-6 col-md-12">
              <StaticImage
                src="../../images/nl/factuur_thumb.png"
                style={{ border: "1px solid #EEEEEE" }}
                className="img-fluid"
                alt={description + " photo"}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-halingo" style={{ fontSize: "2em" }}>
                {title}
              </div>
              <div style={{ fontSize: "1.25em" }}>
                {description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FastAdmin;

import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import SEO from "../seo"

const Layout = ({ children, location }) => {
  return (
    <div>
      <SEO />
      <Navbar location={location}  />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;

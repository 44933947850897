import React, { useState } from "react";

const FAQ = ({
  Prices_FAQ_Title,
  Prices_FAQ_Qs_A,
  Prices_FAQ_Qs_B,
  Prices_FAQ_Qs_C,
  Prices_FAQ_Qs_D,
  Prices_FAQ_Qs_E,
  Prices_FAQ_AN_A,
  Prices_FAQ_AN_B,
  Prices_FAQ_AN_C,
  Prices_FAQ_AN_D,
  Prices_FAQ_AN_E,
  Prices_help_Center,
}) => {
  const [faqActive, setActiveFaq] = useState();
  const faqHandler = (id) => {
    setActiveFaq(id);
  };



const data = [
  {
    id: 1,
    title: Prices_FAQ_Qs_A,
    description: Prices_FAQ_AN_A,
  },
  {
    id: 2,
    title: Prices_FAQ_Qs_B,
    description: Prices_FAQ_AN_B,
  },
  {
    id: 3,
    title:Prices_FAQ_Qs_C ,
    description: Prices_FAQ_AN_C,
  },
  {
    id: 4,
    title: Prices_FAQ_Qs_D,
    description: Prices_FAQ_AN_D,
  },
  {
    id: 5,
    title: Prices_FAQ_Qs_E,
    description: Prices_FAQ_AN_E,
  },
];


  return (
    <div>
      <div className="site-section" id="faq-section">
        <div className="container">
          <div
            className="row align-items-start justify-content-center"
          >
            <div className="col-12 mt-0">
              <h2 className="text-halingo" style={{ textAlign: "center" }}>
                {Prices_FAQ_Title}
              </h2>
            </div>
          </div>
          <div
            className="row align-items-start justify-content-center mt-5"
          >
            <div className="col-12">
              <div className="panel-body">
                <div className="panel-group" id="accordion">
                  {data.map((item, index) => {
                    return (
                      <div
                        className="panel panel-default mb-3"
                        key={index}
                        onClick={() => faqHandler(item.id)}
                        role="button"
                      >
                        <a className="collapsed" aria-expanded="false">
                          <div className="panel-heading shadow-sm px-3 py-2">
                            <h5 className="panel-title my-2 position-relative">
                              {item.title}
                              <span
                                className="icon-arrow_downward faq-icon"
                                aria-hidden="true"
                                style={{ fontSize: "1em" }}
                              ></span>
                            </h5>
                          </div>
                        </a>
                        {faqActive == item.id && (
                          <div className="">
                            <div className="panel-body px-2 pt-2">
                              {item.description}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;

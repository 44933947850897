import React from "react";

const Probeer = ({ Features_Probeer_Title, Features_Probeer_Btn }) => {
  return (
    <div>
      <div className="site-blocks-cover halingo-bg" id="solution-section">
        <div className="container">
          <div
            className="row align-items-center"
          >
            <div className="col-12 mt-5">
              <h2 style={{ textAlign: "center" }}>{Features_Probeer_Title}</h2>
              <div className="mt-5" style={{ textAlign: "center" }}>
                <a
                  href="https://app.halingo.be/register"
                  style={{ fontSize: "1.5em" }}
                  className="btn btn-outline-halingo py-2 px-3 d-sm-inline-block"
                >
                  {Features_Probeer_Btn}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Probeer;
